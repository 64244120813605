@import '../core/mixins';

.searchFilter {
  margin: 10px 0;
  padding: 10px 0;
  background: #ececec;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.searchFilter > div {
  margin: 0;
  padding: 0;
}

.tagDropDownFilter {
  float: left;
  margin: 0;
  padding: 5px 15px;
}

.searchFilter-label {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
}

.searchFilter-control select {
  display: block;
  width: 100%;
  margin: 5px 0 0 0;
}

.searchFilter-submit {
  padding: 5px 15px;
}

.searchFilter-submit button {
  display: inline-block;
  margin: 0;
  padding: 13px 30px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
  background: #ff6808;
  border: 1px solid #ff6808;
  color: #fff;
  -webkit-border-radius: 1.79px;
  -moz-border-radius: 1.79px;
  border-radius: 1.79px;
}

/* media queries */

@include respond-to(mobile) {

  .searchFilter-selection {
    width: 100%;
  }

}

@include respond-to(tablet) {

  .searchFilter-selection {
    width: 50%;
  }

}

@include respond-to(desktop) {

  .searchFilter-selection {
    width: 50%;
  }

}