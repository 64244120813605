/* GSKPrecision Light */
@font-face {
  font-family: 'GSKPrecision';
  font-style: normal;
  font-weight: 200;
  src: local(''),
  url('../../fonts/GSKPrecision-Light.otf') format("opentype"),
  url('../../fonts/GSKPrecision-Light.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/GSKPrecision-Light.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/GSKPrecision-Light.ttf') format('truetype');
}

/* GSKPrecision Light Italic */
@font-face {
  font-family: 'GSKPrecision';
  font-style: italic;
  font-weight: 200;
  src: local(''),
  url('../../fonts/GSKPrecision-LightItalic.otf') format("opentype"),
  url('../../fonts/GSKPrecision-LightItalic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/GSKPrecision-LightItalic.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/GSKPrecision-LightItalic.ttf') format('truetype');
}

/* GSKPrecision Regular */
@font-face {
  font-family: 'GSKPrecision';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('../../fonts/GSKPrecision-Regular.otf') format("opentype"),
  url('../../fonts/GSKPrecision-Regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/GSKPrecision-Regular.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/GSKPrecision-Regular.ttf') format('truetype');
}

/* GSKPrecision Regular Italic */
@font-face {
  font-family: 'GSKPrecision';
  font-style: italic;
  font-weight: 400;
  src: local(''),
  url('../../fonts/GSKPrecision-RegularItalic.otf') format("opentype"),
  url('../../fonts/GSKPrecision-RegularItalic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/GSKPrecision-RegularItalic.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/GSKPrecision-RegularItalic.ttf') format('truetype');
}

/* GSKPrecision Bold */
@font-face {
  font-family: 'GSKPrecision';
  font-style: normal;
  font-weight: 700;
  src: local(''),
  url('../../fonts/GSKPrecision-Bold.otf') format("opentype"),
  url('../../fonts/GSKPrecision-Bold.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/GSKPrecision-Bold.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/GSKPrecision-Bold.ttf') format('truetype');
}

/* GSKPrecision Bold Italic */
@font-face {
  font-family: 'GSKPrecision';
  font-style: italic;
  font-weight: 700;
  src: local(''),
  url('../../fonts/GSKPrecision-BoldItalic.otf') format("opentype"),
  url('../../fonts/GSKPrecision-BoldItalic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/GSKPrecision-BoldItalic.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/GSKPrecision-BoldItalic.ttf') format('truetype');
}