.carousel {
  width: 100%;
  margin: 0;
  padding: 0;
}

.carousel > .component-content {
  position: relative;
  margin: 0 auto;
  padding: 40px 20px 70px 20px;
  border: none;
  max-width: 1180px;
}

.carousel-slides {
  display: block;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
}

.carousel-slide {
  clear: both;
  position: absolute;
  overflow: hidden;
  width: 100%;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}

.carousel-slide > * {
  padding: 0;
}

.carousel-slide.is-active {
  display: block;
  position: relative;
}

.carousel-slide .image-and-content {
  overflow: hidden;
}

.carousel-slide .carousel-cover {
  float: left;
  max-width: 55%;
  object-fit: cover;
}

.carousel-slide .no-content .carousel-cover {
  float: none;
  max-width: 100%;
  object-fit: cover
}

.carousel-slide .carousel-content {
  float: right;
  max-width: 45%;
  padding: 20px;
}

.carousel-slide .carousel-content .box {
  margin: 0;
  padding: 0;
}

.carousel-slide .no-image .carousel-content {
  float: none;
  max-width: 100%;
}

.carousel-cover {
  height: auto;
}

@media only screen and (max-width: 767px) {

  .carousel-slide .carousel-cover {
    float: none;
    max-width: 100%;
  }

  .carousel-slide .carousel-content {
    max-width: 100%;
    float: none;
  }

}

.carousel-nav {
  position: absolute;
  z-index: 999;
  margin: 0;
  padding: 10px 0;
  bottom: 10px;
  width: 100%;
  overflow: hidden;
  text-align: center;
  clear: both;
}

.carousel-nav li {
  display: inline-block;
  margin: 0 1px;
  padding: 0;
}

.carousel-nav li a {
  text-indent: -9999px;
  overflow: hidden;
  background: #ccccc9;
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 10px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.carousel-nav li.is-active a {
  background: #fff;
}

.carousel-nav-prev, .carousel-nav-next {
  display: none;
}

.carousel-nav-prev a, .carousel-nav-next a {
  display: none;
}

.no-js .carousel-slide {
  position: static;
}

.no-js .carousel-slide:first-child {
  margin-top: 0;
  border-top: none;
}

.no-js .carousel-nav li {
  display: none;
}