@import '../core/mixins';

.navigation-mobile-menu {
  display: none;
}

div.navigation {
  margin: 0;
  padding: 0;
  width: auto;
}

ul.navigation-root {
  display: block;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@include respond-to(tablet) {

  ul.navigation-level1 {
    width: 100%;
  }

  li.navigation-item {
    display: block;
    position: relative;
  }

  li.navigation-item a {
    display: block;
    box-sizing: border-box;
    margin: 0;
    padding: 1rem 1.5rem;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-decoration: none;
    color: $text-default;
  }

  ul.navigation-root .is-active a,
  ul.navigation-root .has-active-child:not(.first) a {
    font-weight: 700;
  }

  ul.navigation-level1 > li:first-child a {
    border-top: 1px solid $gray-light-border;
  }

  ul.navigation-level1 > li.has-children > a:before {
    /*@include font-awesome($fa-var-angle-right);*/
  }

  ul.navigation-level1 > li.has-children.is-active > a:before {
    /*@include font-awesome($fa-var-angle-down);*/
  }

  ul.navigation-level1 > li.has-active-child > a:before {
    /*@include font-awesome($fa-var-angle-down);*/
  }

  ul.navigation-level1 > li.has-children.has-children > a:before {
    position: absolute;
    left: 18px;
    font-weight: 900;
    font-size: 0.9rem;
    display: inline-block;
  }

  ul.navigation-level1 li.page-search {
    display: none;
  }

  ul.navigation-level1 li a:hover {
    background: #fff;
  }

  ul.navigation-level2 {
    display: block;
  }

  ul.navigation-level1 > li.is-open ul.navigation-level2,
  ul.navigation-level1 > li.is-active ul.navigation-level2,
  ul.navigation-level1 > li.has-active-child ul.navigation-level2 {
    display: block;
  }

  ul.navigation-level2 li a {
    font-weight: 400;
    background: #fff;
  }

  ul.navigation-level2 li a:hover {
    background: $navigation-hover;
    color: #fff;
  }

  ul.navigation-level2 li.is-active a {
    font-weight: 700;
    background: $navigation-hover;
    color: #fff;
  }

}

@include respond-to(desktop) {

  div.navigation {
    padding: 0 30px;
  }

  ul.navigation-level1 {
    display: flex;
    flex-wrap: nowrap;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  ul.navigation-level1 li {
    position: relative;
  }

  ul.navigation-level1 li a {
    position: relative;
    display: block;
    color: $text-default;
    border-top: 3px solid transparent;
    text-decoration: none;
    height: $header-height;
    line-height: $header-height;
    padding: 0 30px;
    white-space: nowrap;
    font-size: 1.2rem;
    font-weight: 700;
  }

  ul.navigation-level1 > li.has-children a {
    padding-right: 35px;
  }

  ul.navigation-level1 > li.has-children > a:before {
    @include font-awesome($fa-var-angle-down);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    color: $text-light;
  }

  ul.navigation-level1 > li > a:hover {
    border-top: 3px solid $navigation-hover;
  }

  ul.navigation-level1 > li.is-active:not(.first) > a, ul.navigation-level1 > li.has-active-child:not(.first) > a {
    font-weight: 700;
    background: transparent;
    line-height: $header-height;
    border-top: 3px solid $navigation-active;
  }

  ul.navigation-level1 li.page-search a {
    position: relative;
    color: transparent;
    font-size: 0;
    width: 30px;
  }

  ul.navigation-level1 li.page-search a:before {
    @include font-awesome($fa-var-search);
    position: absolute;
    visibility: visible;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 900;
    font-size: 0.9rem;
    color: $text-default;
  }

  /* Level 2 */

  ul.navigation-level2 {
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    margin: 0;
    padding: 15px;
    background: $navigation-menu-flyout;
    list-style-type: none;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    z-index: 9999;
  }

  ul.navigation-level2:before {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -10px;
    right: 10px;
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $navigation-menu-flyout;
    z-index: 2;
  }

  ul.navigation-level2:after {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -12px;
    right: 8px;
    content: "";
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid $navigation-menu-flyout;
    z-index: 1;
  }

  ul.navigation-level2 li a {
    display: block;
    padding: 10px 15px;
    height: auto;
    line-height: 1.5rem;
    color: #fff;
  }

  ul.navigation-level2 > li:last-child a {
    border: none;
  }

  ul.navigation-level2 > li a:hover {
    text-decoration: underline;
  }

  ul.navigation-level2 > li.is-active > a {
    position: relative;
    padding-left: 40px;
  }

  ul.navigation-level2 > li.is-active > a:before {
    @include font-awesome($fa-var-angle-right);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
  }

  ul.navigation-level2 > li.is-active > a:hover {
    background: transparent;
  }

  ul.navigation-level1 > li:hover > ul {
    display: block;
  }

}