@import 'mixins';

// This fixes author device emulator background overwrite problem.
#cq-emulator-content.firefox {
  background-color: #fff !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 15px 0;
}

a {
  color: #000;
  text-decoration: none;

  &:hover {
    color: #000;
  }
}

.selection-bold {
  font-weight: 900;
}

.selection-italic {
  font-style: italic;
}

input[type="search"] {
  box-sizing: content-box;
}

.component {

  &-content {
    @include clearfix;
  }

}

.link {
  color: $link-default;
  text-decoration: underline;
}

.clearfix {
  &:before,
  &:after {
    content: " "; /* 1 */
    display: table; /* 2 */
  }

  &:after {
    clear: both;
  }
}

.pull {
  &-right {
    float: right;
  }

  &-left {
    float: left;
  }
}

.only-mobile, .only-tablet, .only-desktop {
  display: none;
}

.center {
  clear: both;
  float: none;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}

/* videos */

.jwplayer.jw-skin-site .jw-display-icon-container {
  border-style: solid;
  border-width: medium;
  border-radius: 50%;
  padding: 1em;
}

.jwplayer.jw-skin-alaska .jw-display-controls .jw-display-icon-container .jw-icon,
.jwplayer.jw-skin-alaska .jw-display-controls .jw-display-icon-container .jw-icon {
  color: $button-primary-bg;
}

/* forms */

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

/* spacing */

.no-space {
  margin: 0;
  padding: 0;
}

.space-inside-top {
  padding-top: 30px;
}

.space-inside-left {
  padding-left: 30px;
}

.space-inside-right {
  padding-right: 30px;
}

.space-inside-bottom {
  padding-bottom: 30px;
}

/* alignments */

.middle {
  margin: 0 auto;
  text-align: center;
}

.right {
  margin: 0 0 0 auto;
  text-align: right;
}

.vertically-middle .content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.vertically-middle .content > * {
  display: inline-block;
}

/* borders */

.border-top {
  border-top: 1px solid $border-default;
}

.border-left {
  border-left: 1px solid $border-default;
}

.border-right {
  border-right: 1px solid $border-default;
}

.border-bottom {
  border-bottom: 1px solid $border-default;
}

/* misc */

.is-hidden {
  display: none !important;
  visibility: hidden !important;
}

.paragraphSystem, .row {
  margin: 0;
}

/* main */

html, body {
  width: 100%;
  height: 100%;
  font-size: 1rem;
  background: $background-default;
}

body {
  position: relative;
  overflow: auto;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'GSKPrecision', sans-serif;
  font-weight: 300;
}

.container {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
}

body > .container > .row {
  display: flex;
  flex-basis: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

body > .container > .row > #content {
  flex-grow: 1;
}

body > .container > .row > #header,
body > .container > .row > #content,
body > .container > .row > #footer {
  flex-shrink: 0;
}

/* layout: header */

div#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: $header-height;
  width: 100%;
  max-width: 100%;
  padding: 0;
  overflow: visible;
  background: #fff;
  z-index: 9999;
}

div#header div[class*="col-"] {
  padding-left: 15px;
  padding-right: 15px;
}

.reference-header > .inner {
  margin: 0 auto;
  max-width: 1140px;
}

.reference-header > .inner > .component-content {
  display: flex;
  flex-wrap: nowrap;
  flex-basis: 100%;
  justify-content: space-between;
}

.reference-header .header-sidebar > div {
  margin-left: auto;
}

.reference-header .image {
  padding-top: 0;
  padding-bottom: 0;
}

.reference-header .richText {
  padding-top: 0;
  padding-bottom: 0;
}

.reference-header .header-logo{
  min-width: 200px;
}

.reference-header .header-logo-secondary {
  display: block;
  text-align: right;
  width: auto;
}

.reference-header .header-logo > div,
.reference-header .header-logo-secondary > div {
  text-align: center;
}

.reference-header .header-logo a,
.reference-header .header-logo-secondary > div {
  display: inline-block;
  height: $header-height;
  line-height: 66px;
  padding: 0 20px;
}

.reference-header .header-logo img,
.reference-header .header-logo-secondary img {
  display: inline-block;
  vertical-align: middle;
}

.reference-header .header-logo img {
  height: 48px;
  max-height: 48px;
}

.reference-header .header-logo-secondary img {
  height: 20px;
  max-height: 20px;
}

.reference-header .box-heading {
  display: none;
}

/* layout: content */

div#content {
  margin-top: $header-height;
  padding: 0;
  background: #fff;
}

div#content .layout-inner > div.col-xs-12, div#content .layout-inner > div.col-md-12 {
  padding-left: 0;
  padding-right: 0;
}

#content h1 {
  margin: 0;
  padding: 10px 0 0 0;
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 2.4rem;
  color: $headline-primary-text;
}

#content h2 {
  margin: 0;
  padding: 10px 0 0 0;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2.2rem;
  color: $headline-secondary-text;
}

#content h3 {
  margin: 0;
  padding: 10px 0 0 0;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.8rem;
  color: $headline-primary-text;
}

#content h4 {
  margin: 0;
  padding: 10px 0 0 0;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
  color: $headline-secondary-text;
}

/* title border */

.headline-border h1,
.headline-border h2,
.headline-border h3,
.headline-border h4,
.headline-border h5,
.headline-border h6 {
  display: inline-block;
}

/* bg styles */

.bg-gray-light {
  background-color: $gray-light-bg;
}

.bg-green-light {
  background-color: $green-light-bg;
}

.bg-green-dark {
  background-color: $green-dark-bg;
}

/* formatting */

.superscript {
  font-size: 40%;
  vertical-align: super;
}

/* divider */

.divider {
  display: block;
  width: 100%;
  padding: 15px 15px 0 15px;
  clear: both;
}

.divider > div {
  display: block;
  width: 100%;
  height: 15px;
  border-top: 1px solid $border-default;
}

.divider.clearer > div {
  height: 0.01rem;
  clear: both;
  border: none;
}

.divider.spacer {
  display: block;
  width: 100%;
  padding: 20px 20px 0 20px;
}

.divider.spacer > div {
  display: block;
  width: 100%;
  height: 20px;
  border-top: none;
}

/* approval code */

.approval-code, .note {
  font-size: 0.8rem;
  font-weight: 300;
  line-height: 1.2rem;
  color: $text-light;
}

/* quote */

span.quote {
  display: inline;
  position: relative;
  min-height: 20px;
  margin: 40px 20px 40px 20px;
  padding: 10px 10px 10px 10px;
}

span.quote:before {
  display: inline-block;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  left: -40px;
  margin: 0;
  background-image: url(../../img/quote-start.svg);
  background-size: 3rem;
  background-repeat: no-repeat;
  background-position: top left;
  content: " ";
}

span.quote:after {
  display: inline-block;
  position: absolute;
  width: 50px;
  height: 50px;
  bottom: 0;
  right: -40px;
  margin: 0;
  background-image: url(../../img/quote-start.svg);
  background-size: 3rem;
  background-repeat: no-repeat;
  background-position: bottom right;
  content: " ";
}

/* buttons */

.button-primary, #content .richText a.button-primary,
.button-secondary, #content .richText a.button-secondary {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  margin: 10px 0 0 0;
  padding-right: 110px;
  padding-left: 30px;
  height: 50px;
  line-height: 50px;
  font-size: 1.2rem;
  font-weight: 700;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.button-primary, #content .richText a.button-primary {
  color: $button-primary-text;
  background: $button-primary-bg url(../../img/arrow-white.svg) calc(100% - 30px) center no-repeat;
}

.button-primary:hover, #content .richText a.button-primary:hover {
  color: $button-secondary-text;
  background: $button-secondary-bg url(../../img/arrow-green.svg) calc(100% - 25px) center no-repeat;
}

.button-secondary, #content .richText a.button-secondary {
  color: $button-secondary-text;
  background: $button-secondary-bg url(../../img/arrow-green.svg) calc(100% - 30px) center no-repeat;
}

.button-secondary:hover, #content .richText a.button-secondary:hover {
  color: $button-primary-text;
  background: $button-primary-bg url(../../img/arrow-white.svg) calc(100% - 25px) center no-repeat;
}

/* color bubbles */

.color-bubble-dark-green,
.color-bubble-purple,
.color-bubble-pink,
.color-bubble-yellow {
  padding: 0 0 0 20px;
}

.color-bubble-dark-green:before,
.color-bubble-purple:before,
.color-bubble-pink:before,
.color-bubble-yellow:before {
  position: absolute;
  top: 6px;
  left: 0;
  font-size: 0.5rem;
  @include font-awesome($fa-var-circle);
}

.color-bubble-dark-green:before {
  color: #008eaa;
}

.color-bubble-purple:before {
  color: #4f4587;
}

.color-bubble-pink:before {
  color: #ec128a;
}

.color-bubble-yellow:before {
  color: #f5be5e;
}

/* icons */

.lock-icon:before {
  @include font-awesome($fa-var-lock);
  padding-right: 10px;
}

.calendar-info:before {
  @include font-awesome($fa-var-calendar);
  padding-right: 10px;
}

.time-info:before {
  @include font-awesome($fa-var-clock);
  padding-right: 10px;
}

.icon-pdf:before {
  @include font-awesome($fa-var-file-pdf);
  padding-right: 10px;
}

.icon-download:before {
  @include font-awesome($fa-var-download);
  padding-right: 10px;
}

.icon-back:before {
  @include font-awesome($fa-var-chevron-left);
  padding-right: 10px;
}

.fa-files-medical {
  list-style-type: none;
}

.fa-files-medical,
.fa-clipboard-medical,
.fa-bullseye-arrow,
.fa-hospital,
.fa-calendar-clock,
.fa-heart-pulse {
  display: inline-block;
  position: relative;
  padding-left: 30px;
}

.fa-files-medical:before,
.fa-clipboard-medical:before,
.fa-bullseye-arrow:before,
.fa-hospital:before,
.fa-calendar-clock:before,
.fa-heart-pulse:before {
  position: absolute;
  top: 1px;
  left: 0;
  color: $link-default;
}

.fa-files-medical:before {
  @include font-awesome($fa-var-files-medical);
}

.fa-clipboard-medical:before {
  @include font-awesome($fa-var-clipboard-medical);
}

.fa-bullseye-arrow:before {
  @include font-awesome($fa-var-clipboard-medical);
}

.fa-hospital:before {
  @include font-awesome($fa-var-clipboard-medical);
}

.fa-calendar-clock:before {
  @include font-awesome($fa-var-calendar-clock);
}

.fa-heart-pulse:before {
  @include font-awesome($fa-var-heart-pulse);
}

/* layout: content / image */

.image {
  position: relative;
  padding-top: 30px;
  padding-bottom: 15px;
}

.image figcaption {
  font-size: 0.9rem;
  line-height: 1.4rem;
}

.image a {
  display: block;
  position: relative;
}

.image img {
  max-width: 100%;
}

.image-overlay-circle {
  position: relative;
}

.image-crop-circle img {
  clip-path: circle();
  width: 100%;
  height: 100%;
  object-fit: cover
}

.image-round-corners img {
  border-radius: 30px;
}

.image-overlay-circle:before {
  position: absolute;
  top: -10%;
  left: 0;
  z-index: 20;
  height: 120%;
  width: 120%;
  margin: 0;
  padding: 0;
  background-image: url('../../img/circle-overlay.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  content: " ";
}

/* layout: content / richText */

#content .richText {
  margin-top: 20px;
}

#content .richText p {
  font-size: 1rem;
  line-height: 1.6rem;
  margin: 0;
  padding: 15px 0 0 0;
  color: $text-default;
}

#content .bg-gray-light .richText p,
#content .bg-green-light .richText p {
  color: $text-default;
}

#content .bg-green-dark .richText p {
  color: $text-dark;
}

#content .richText a {
  font-size: 1rem;
  line-height: 1.6rem;
  color: $link-default;
  text-decoration: underline;
}

#content .bg-green-dark .richText a {
  color: $link-dark;
  text-decoration: underline;
}

#content .richText ul {
  margin: 15px 0 15px 0;
  padding: 0;
  list-style-type: none;
}

#content .richText ul li {
  position: relative;
  margin: 12px 0;
  padding: 0 0 0 20px;
  font-size: 1rem;
  line-height: 1.6rem;
  color: $text-default;
}

#content .richText.no-list-type ul li {
  padding: 0;
}

#content .richText ul li:before {
  position: absolute;
  top: 6px;
  left: 0;
  font-size: 0.5rem;
  color: $link-default;
  @include font-awesome($fa-var-circle);
}

#content .richText.no-list-type ul li:before {
  display: none;
  content: "";
}

#content .richText ol {
  margin: 15px 0 15px 30px;
  padding: 0;
}

#content .richText ol li {
  position: relative;
  margin: 12px 0;
  padding: 0 0 0 15px;
  font-size: 1rem;
  line-height: 1.6rem;
  color: $text-default;
}

/* layout: content / article teaser v2 */

#content .articleTeaserV2 {
  cursor: pointer;
}

/* layout: content / footnote */

#content .footnote {
  display: block;
  padding: 20px 40px;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $text-default;
  background: $footnote-bg;
  border-top: 5px solid $footnote-border;
}

/* layout: content / article compact */

#content .article-compact {
  clear: both;
  float: none;
  width: 100%;
}

#content .article-compact > div {
  float: none;
  margin: 0 auto;
  padding-top: 0;
  padding-bottom: 15px;
  max-width: 1140px;
}

/* layout: content / article compact */

#content .box-compact {
  padding-left: 15px;
  padding-right: 15px;
}

/* layout: content / title */

div.title > div {
  padding-top: 20px;
}

/* layout: content / list bubble */

.list-bubble-primary, .list-bubble-secondary {
  display: block;
  padding: 15px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.list-bubble-primary {
  color: $highlight-primary-text;
  background: $highlight-primary-bg-end;
  background-image: -webkit-gradient(linear, left bottom, right top, color-stop(0%, $highlight-primary-bg-start), color-stop(100%, $highlight-primary-bg-end));
  background-image: -webkit-linear-gradient(45deg, $highlight-primary-bg-start 0%, $highlight-primary-bg-end 100%);
  background-image: -moz-linear-gradient(45deg, $highlight-primary-bg-start 0%, $highlight-primary-bg-end 100%);
  background-image: -o-linear-gradient(45deg, $highlight-primary-bg-start 0%, $highlight-primary-bg-end 100%);
  background-image: -ms-linear-gradient(45deg, $highlight-primary-bg-start 0%, $highlight-primary-bg-end 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$highlight-primary-bg-start', endColorstr='$highlight-primary-bg-end', GradientType=1);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient (GradientType=0, startColorstr=$highlight-primary-bg-start, endColorstr=$highlight-primary-bg-end)";
  background-image: linear-gradient(45deg, $highlight-primary-bg-start 0%, $highlight-primary-bg-end 100%);
}

.list-bubble-secondary {
  color: $highlight-secondary-text;
  background: $highlight-secondary-bg-end;
  background-image: -webkit-gradient(linear, left bottom, right top, color-stop(0%, $highlight-secondary-bg-start), color-stop(100%, $highlight-secondary-bg-end));
  background-image: -webkit-linear-gradient(45deg, $highlight-secondary-bg-start 0%, $highlight-secondary-bg-end 100%);
  background-image: -moz-linear-gradient(45deg, $highlight-secondary-bg-start 0%, $highlight-secondary-bg-end 100%);
  background-image: -o-linear-gradient(45deg, $highlight-secondary-bg-start 0%, $highlight-secondary-bg-end 100%);
  background-image: -ms-linear-gradient(45deg, $highlight-secondary-bg-start 0%, $highlight-secondary-bg-end 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$highlight-secondary-bg-start', endColorstr='$highlight-secondary-bg-end', GradientType=1);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient (GradientType=0, startColorstr=$highlight-secondary-bg-start, endColorstr=$highlight-secondary-bg-end)";
  background-image: linear-gradient(45deg, $highlight-secondary-bg-start 0%, $highlight-secondary-bg-end 100%);
}

/* layout: content / search box */

.search-results .searchBox form {
  position: relative;
  margin: 0;
  padding: 10px 0;
  box-sizing: border-box;
  height: $header-height;
}

.search-results .searchBox .searchBox-title {
  display: none;
}

.search-results .searchBox form label {
  display: block;
  font-weight: 300;
}

.search-results .searchBox form input {
  box-sizing: border-box;
  width: 100%;
  height: 44px;
  margin: 0;
  padding: 4px 40px 4px 3px;
  line-height: 2rem;
  border: 1px solid $border-default;
  font-size: 1rem;
  text-indent: 12px;
}

.search-results .searchBox form button {
  position: absolute;
  top: 13px;
  right: 0;
  height: 40px;
  width: 40px;
  background: none;
  border: 0;
}

.search-results .searchBox form button {
  font-size: 0;
  color: transparent;
  cursor: pointer;
}

/* layout: search results / search box */

.search-results form {
  position: relative;
  margin: 0;
  padding: 10px 0;
  box-sizing: border-box;
  height: $header-height;
}

.search-results .searchBox-title {
  display: none;
}

.search-results .searchBox form input {
  box-sizing: border-box;
  width: 100%;
  height: 44px;
  margin: 0;
  padding: 4px 40px 4px 3px;
  line-height: 2rem;
  border: 1px solid $border-default;
  background: #fff;
  font-size: 1rem;
  text-indent: 12px;
  color: $text-default;
}

.search-results .searchBox form button {
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  width: 40px;
  background: none;
  border: 0;
}

.search-results .searchBox form button,
.search-results .searchBox form button:hover,
#content .search-results .button {
  padding: 13px 15px;
  font-size: 0;
  color: transparent;
  background: transparent;
  border: none;
  cursor: pointer;
}

.search-results .searchBox form button:before {
  position: absolute;
  content: " ";
  /*background-image: url(../../img/icons/search.svg);*/
  background-size: 24px 24px;
  height: 24px;
  width: 24px;
  left: 6px;
  display: inline-block;
  color: #000;
}

/* layout: content / article teaser */

.reference-article-teaser {
  margin: 20px 0 0 0;
  padding-bottom: 10px;
}

.reference-article-teaser > div {

}

.article-teaser .article-teaser-image {
  background: #fff;
}

.article-teaser .article-teaser-image .article-teaser-bg-image {
  height: 200px;
  width: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.article-teaser .article-teaser-image-icon .article-teaser-bg-image {
  background-size: contain;
}

.article-teaser-image img {
  display: none;
}

.article-teaser-content h1, #content .article-teaser-content h1 {
  margin: 0;
  padding: 15px 0 0 0;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.3rem;
  color: $headline-primary-text;
}

.article-teaser-content h2, #content .article-teaser-content h2 {
  margin: 0;
  padding: 15px 0 0 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.9rem;
  color: $headline-secondary-text;
}

.article-teaser-content h3, #content .article-teaser-content h3 {
  margin: 0;
  padding: 15px 0 0 0;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.5rem;
  color: $headline-primary-text;
}

.article-teaser-content p, #content .article-teaser-content p {
  margin: 0;
  padding: 10px 0 0 0;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4rem;
  color: $text-default;
}

.article-teaser-cta {
  margin-top: 10px;
}

/* layout: content / article teaser default */

.article-teaser-default .article-teaser-bg-image {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

/* layout: content / article teaser border */

.article-teaser-border {
  border: 1px solid $article-teaser-border;
  background: $article-teaser-border-bg;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  overflow: hidden;
}

.article-teaser-border .article-teaser-content {
  box-sizing: border-box;
  padding: 30px;
}

/* layout: content / highlight box white */

.highlight-box-white {
  margin: 30px 0 0 0;
}

.highlight-box-white > div {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 15px;
  box-sizing: border-box;
  background: #fff;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}

/* layout: content / highlight box blue */

.highlight-box-blue {
  margin: 30px 0 0 0;
  padding-bottom: 30px;
}

.highlight-box-blue > div {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: $hightlight-box-blue-bg url(../../img/bg-bubble-top-left.svg) top left no-repeat;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}

.highlight-box-blue > div:after {
  position: absolute;
  width: 400px;
  height: 400px;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-image: url(../../img/bg-bubble-bottom-right.svg);
  background-position: bottom right;
  background-repeat: no-repeat;
  pointer-events: none;
}

/* layout: content / highlight box gradient */

.highlight-box-gradient {
  margin: 30px 0 0 0;
  padding-bottom: 20px;
}

.highlight-box-gradient > div {
  position: relative;
  overflow: hidden;
  margin: 0;
  box-sizing: border-box;
  background-image: url(../../img/gradient-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}

.highlight-box-gradient > div:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-image: url(../../img/bg-bubble-top-left-white.svg);
  background-position: top left;
  background-repeat: no-repeat;
  pointer-events: none;
}

.highlight-box-gradient > div:after {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-image: url(../../img/bg-bubble-bottom-right-white.svg);
  background-position: bottom right;
  background-repeat: no-repeat;
  pointer-events: none;
}

#content .highlight-box-gradient  h1 {
  color: #fff;
}

/* layout: content / highlight box gradient dark */

.highlight-box-gradient-dark {
  margin: 30px 0 0 0;
  padding-bottom: 20px;
}

.highlight-box-gradient-dark > div {
  position: relative;
  overflow: hidden;
  margin: 0;
  box-sizing: border-box;
  background: rgb(3,116,133);
  background: linear-gradient(90deg, rgba(3,116,133,1) 0%, rgba(49,182,191,1) 100%);
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}

.highlight-box-gradient-dark > div:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-image: url(../../img/bg-bubble-top-left-white.svg);
  background-position: top left;
  background-repeat: no-repeat;
  pointer-events: none;
  opacity: 0.5;
}

.highlight-box-gradient-dark > div:after {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-image: url(../../img/bg-bubble-bottom-right-white.svg);
  background-position: bottom right;
  background-repeat: no-repeat;
  pointer-events: none;
  opacity: 0.5;
}

#content .highlight-box-gradient-dark .richText {
  margin: 0;
  padding: 15px 40px;
}

#content .highlight-box-gradient-dark h1 {
  color: $text-dark;
}

#content .highlight-box-gradient-dark .richText p {
  color: $text-dark;
}

#content .highlight-box-gradient-dark .richText a {
  color: $text-dark;
  text-decoration: underline;
}

#content .highlight-box-gradient-dark .richText ul li {
  color: $text-dark;
}

#content .highlight-box-gradient-dark .richText ul li:before {
  color: $text-dark;
}

#content .highlight-box-gradient-dark .richText ol li {
  color: $text-dark;
}

/* layout: content / box headline white */

#content .box-headline-white-left h1,
#content .box-headline-white-right h1{
  margin: 0;
  padding: 0;
}

.box-headline-white-left > div {
  margin: 0;
  padding: 20px;
  background: #fff;
  -webkit-border-top-right-radius: 30px;
  -webkit-border-bottom-right-radius: 30px;
  -moz-border-radius-topright: 30px;
  -moz-border-radius-bottomright: 30px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.box-headline-white-right > div {
  margin: 0;
  padding: 20px;
  background: #fff;
  -webkit-border-top-left-radius: 30px;
  -webkit-border-bottom-left-radius: 30px;
  -moz-border-radius-topleft: 30px;
  -moz-border-radius-bottomleft: 30px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

/* layout: content / highlight box border green */

.highlight-box-border-green {
  margin: 50px 0 0 0;
}

.highlight-box-border-green > div {
  position: relative;
  padding: 25px;
  background: #fff;
  border: 1px solid $hightlight-box-border-green-bg;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  box-sizing: border-box;
}

.highlight-box-border-green div.highlight-box-headline {
  display: block;
  position: absolute;
  top: -28px;
  margin: 0;
  padding: 0;
  text-align: center;
}

.highlight-box-border-green div.highlight-box-headline > div {
  display: inline-block;
  background: #fff;
  padding-top: 0;
  padding-left: 20px;
  padding-right: 20px;
}

/* layout: content / box footnote */

.box-footnote {
  margin: 20px 0 0 0;
}

.box-footnote > div {
  margin: 0;
  padding: 20px 30px;
  background: $footnote-bg;
  overflow-x: auto;
}

/* layout: footer */

div#footer {
  margin: 15px 0 0 0;
  padding: 20px 0 0 0;
}

.reference-footer {
  padding: 0;
}

.reference-footer > .inner {
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 30px 50px 30px 50px;
  max-width: 1140px;
  background: $gray-light-bg;
  -webkit-border-top-left-radius: 30px;
  -webkit-border-top-right-radius: 30px;
  -moz-border-radius-topleft: 30px;
  -moz-border-radius-topright: 30px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.reference-footer > .inner > .component-content {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}

.reference-footer .footer-logo {
  min-width: 250px;
  max-width: 250px;
  padding: 20px 0 0 0;
  flex-grow: 1;
  flex-shrink: 1;
}

.reference-footer .footer-logo img {
  height: 22px;
}

.reference-footer .footer-content {
  margin: 0;
  padding: 20px 0 0 0;
  flex-grow: 1;
  flex-shrink: 1;
}

.reference-footer .footer-content .footer-nav {
  margin: 0;
  padding: 0 0 20px 0;
}

.reference-footer .footer-content .footer-nav ul {
  display: block;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.reference-footer .footer-content .footer-nav ul li {
  display: inline-block;
}

.reference-footer .footer-content .footer-nav ul li a,
.reference-footer .footer-content .footer-nav ul li a:hover {
  display: inline-block;
  font-size: 1.1rem;
  line-height: 1.4rem;
  color: $text-default;
  padding: 5px 10px 5px 0;
}

.reference-footer .footer-content .footer-nav ul li a:hover {
  text-decoration: underline;
}

.reference-footer .footer-content .footer-copyright {
  margin: 0;
  padding: 30px 0 0 0;
  color: $text-light;
  line-height: 1.4rem;
  border-top: 1px solid $gray-light-border;
}

.reference-footer .footer-content .footer-approval {
  margin: 0;
  padding: 20px 0 0 0;
  color: $text-light;
  line-height: 1.4rem;
}

/* approval code */

.approval-code {
  font-size: 0.8rem;
  font-weight: 300;
  line-height: 1.2rem;
  color: #ccc;
}

/* boxes: video asset / jwplayer */

.reference-video-jwplayer {
  @include align-center(medium);
  padding: 0 20px;
}

.reference-video-jwplayer .videoAsset h3 {
  padding-bottom: 20px;
}

.reference-video-jwplayer .videoAsset p {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.7rem;
  margin: 0;
  padding: 10px 0;
}

.reference-video-jwplayer .videoAsset-video {
  display: block;
  position: relative;
  min-height: 20px;
  background: #fefefe;
  margin: 0;
}

.reference-video-jwplayer .videoAsset-description {
  padding: 10px 0 0 0;
}

.reference-video-jwplayer .videoAsset span.videoAsset-duration {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 5px 10px;
  font-size: 0.85rem;
  background: #000;
  color: #fff;
}

/* layout: content / color filters */

.color-filters {
  padding: 10px 20px;
  background: #fff;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  box-sizing: border-box;
}

#content .color-filters ul li, #content .richText.color-filters ul li {
  display: inline-block;
  margin: 10px;
}

/* media queries */

@include respond-to(mobile) {

  /* display */

  .only-desktop {
    display: none;
  }

  .only-mobile {
    display: block !important;
  }

  .center-mobile {
    text-align: center;
  }

  /* spacing */

  .space-inside-top-mobile {
    padding-top: 30px;
  }

  .space-inside-left-mobile {
    padding-left: 30px;
  }

  .space-inside-right-mobile {
    padding-right: 30px;
  }

  .space-inside-bottom-mobile {
    padding-bottom: 30px;
  }

  /* borders */

  .border-top-mobile {
    border-top: 1px solid $border-default;
  }

  .border-left-mobile {
    border-left: 1px solid $border-default;
  }

  .border-right-mobile {
    border-right: 1px solid $border-default;
  }

  .border-bottom-mobile {
    border-bottom: 1px solid $border-default;
  }

  /* headlines */

  #content h1 {
    font-size: 1.3rem;
    line-height: 2rem;
  }

  /* table image head */

  .table-head, #content .table-head:first-of-type {
    margin-top: 15px;
  }

  /* table image teaser */

  .table-image-teaser {
    margin-top: 15px;
  }

  /* container */

  .divider {
    @include align-center(medium);
    padding-top: 15px;
  }

  .divider > div {
    height: 15px;
  }

  /* layout: content */

  .main .richText img {
    display: block;
    float: none;
    width: auto;
    margin: 40px auto;
  }

}

@include respond-to(tablet) {

  .container {
    right: 0;
    -webkit-transition: right .25s ease-out;
    -moz-transition: right .25s ease-out;
    -o-transition: right .25s ease-out;
    transition: right .25s ease-out;
  }

  .nav-is-open .container {
    display: block;
    right: -$navigation-drawer;
  }

  div#header {
    -webkit-transition: left .25s ease-out;
    -moz-transition: left .25s ease-out;
    -o-transition: left .25s ease-out;
    transition: left .25s ease-out;
  }

  #header .searchBox {
    display: block;
    width: 100%;
  }

  #header .searchBox form {
    position: relative;
    margin: 0;
    box-sizing: border-box;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    height: $header-height;
  }

  #header .searchBox .searchBox-title {
    display: none;
  }

  #header .searchBox form label {
    display: block;
    font-weight: 300;
  }

  #header .searchBox form input {
    box-sizing: border-box;
    width: 100%;
    height: 44px;
    margin: 0;
    padding: 4px 40px 4px 3px;
    line-height: 2rem;
    border: 1px solid $border-default;
    background: #fff;
    font-size: 1rem;
    text-indent: 12px;
    color: $text-default;
  }

  #header .searchBox form button {
    position: absolute;
    top: 12px;
    right: 15px;
    height: 40px;
    width: 40px;
    background: none;
    border: 0;
  }

  #header .searchBox form button {
    color: transparent;
    font-size: 0;
    cursor: pointer;
  }

  #header .searchBox form button:before {
    position: absolute;
    content: " ";
    background-size: 24px 24px;
    height: 24px;
    width: 24px;
    top: 8px;
    left: 6px;
    font-weight: 900;
    font-size: 1.2rem;
    display: inline-block;
    color: #000;
  }

  .nav-is-open div#header {
    left: $navigation-drawer;
  }

  .reference-header > .inner > .component-content > div {
    display: block;
  }

  .reference-header .mobile-menu {
    flex-shrink: 0;
    flex-grow: 0;
    cursor: pointer;
    line-height: 66px;
    width: 80px;
    text-align: center;
  }

  .reference-header .mobile-menu > div.component-content {
    display: inline-block;
    width: 2em;
    height: 2em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

  .reference-header .mobile-menu > div.component-content > * {
    display: none;
  }

  .reference-header .header-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: $navigation-drawer !important;
    min-height: 100vh;
    margin-left: -$navigation-drawer;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
    background: $gray-light-bg;
    -ms-overflow-style: none;
    overflow: auto;
  }

  .nav-is-open .reference-header .header-sidebar {
    margin-left: 0;
  }

  .reference-header .header-sidebar:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    width: 10px;
    min-height: 100%;
    box-shadow: inset -7px 0 9px -7px rgba(224, 224, 221, 0.6);
    content: "";
  }

  .reference-header .header-logo-secondary {
    flex-grow: 1;
  }

  /* display */

  .only-tablet {
    display: block !important;
  }

  /* spacing */

  .space-inside-top-tablet {
    padding-top: 30px;
  }

  .space-inside-left-tablet {
    padding-left: 30px;
  }

  .space-inside-right-tablet {
    padding-right: 30px;
  }

  .space-inside-bottom-tablet {
    padding-bottom: 30px;
  }

  /* borders */

  .border-top-tablet {
    border-top: 1px solid $border-default;
  }

  .border-left-tablet {
    border-left: 1px solid $border-default;
  }

  .border-right-tablet {
    border-right: 1px solid $border-default;
  }

  .border-bottom-tablet {
    border-bottom: 1px solid $border-default;
  }

  /* layout: content / multi presenter */

  .reference-multi-presenter {
    margin: 30px 0 0 0;
    padding: 0;
  }

  .reference-multi-presenter > div {
    margin: 0;
    padding: 0;
  }

  .multi-presenter {
    position: relative;
    min-height: 450px;
  }

  .multi-presenter .multi-presenter-bg {
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
  }

  .multi-presenter .multi-presenter-primary h1, #content .multi-presenter .multi-presenter-primary h1,
  .multi-presenter .multi-presenter-secondary h1, #content .multi-presenter .multi-presenter-secondary h1 {
    margin: 0;
    padding: 10px 0 0 0;
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 2.3rem;
    color: $headline-primary-text;
  }

  .multi-presenter .multi-presenter-primary h2, #content .multi-presenter .multi-presenter-primary h2,
  .multi-presenter .multi-presenter-secondary h2, #content .multi-presenter .multi-presenter-secondary h2 {
    margin: 0;
    padding: 10px 0 0 0;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.9rem;
    color: $headline-secondary-text;
  }

  .multi-presenter .multi-presenter-primary h3, #content .multi-presenter .multi-presenter-primary h3,
  .multi-presenter .multi-presenter-secondary h3, #content .multi-presenter .multi-presenter-secondary h3 {
    margin: 0;
    padding: 10px 0 0 0;
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 1.5rem;
    color: $headline-primary-text;
  }

  .multi-presenter .multi-presenter-primary p, #content .multi-presenter .multi-presenter-primary p,
  .multi-presenter .multi-presenter-secondary p, #content .multi-presenter .multi-presenter-secondary p {
    margin: 0;
    padding: 10px 0 0 0;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.4rem;
    color: $headline-primary-text;
  }

  .multi-presenter .multi-presenter-cta {
    width: 80%;
    margin: 0 auto;
    padding: 20px 0 0 0;
    text-align: center;
  }

  .multi-presenter .multi-presenter-cta h1, #content .multi-presenter .multi-presenter-cta h1,
  .multi-presenter .multi-presenter-cta h2, #content .multi-presenter .multi-presenter-cta h2,
  .multi-presenter .multi-presenter-cta h3, #content .multi-presenter .multi-presenter-cta h3,
  .multi-presenter .multi-presenter-cta p, #content .multi-presenter .multi-presenter-cta p {
    margin: 0;
    padding: 10px 0 0 0;
  }

  /* layout: content / multi presenter > simple */

  .multi-presenter-simple {
    position: relative;
  }

  .multi-presenter-simple .multi-presenter-bg {
    position: relative;
    min-height: 600px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    overflow: hidden;
  }

  .multi-presenter-simple .multi-presenter-bg::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-position: bottom;
    background-size: cover;
    content: " ";
  }

  .multi-presenter-simple .multi-presenter-image img {
    display: none;
  }

  .multi-presenter-simple-gradient .multi-presenter-bg:before {
    background-image: url(../../img/multi-presenter-gradiant-overlay-mobile.png);
  }

  .multi-presenter-simple-transparent .multi-presenter-bg:before {
    background-image: url(../../img/multi-presenter-transparent-overlay-mobile.png);
  }

  .multi-presenter-simple .multi-presenter-image .multi-presenter-bg-image {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-position: center center;
    background-size: cover;
  }

  .multi-presenter-simple .multi-presenter-content {
    position: absolute;
    box-sizing: border-box;
    padding: 15px 15px 15px 30px;
    bottom: 40px;
    left: 0;
    width: 90%;
    height: auto;
    z-index: 100;
    overflow-y: auto;
  }

  .multi-presenter-simple-gradient .multi-presenter-content {
    background: #fff;
    -webkit-border-top-right-radius: 40px;
    -webkit-border-bottom-right-radius: 40px;
    -moz-border-radius-topright: 40px;
    -moz-border-radius-bottomright: 40px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
  }

  /* layout: content / multi presenter > extended */

  .multi-presenter-extended {
    height: auto;
  }

  .multi-presenter-extended .multi-presenter-bg {
    padding: 20px 0;
    background-image: url('../../img/multi-presenter-bg.svg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .multi-presenter-extended .multi-presenter-bg-image {
    display: none;
  }

  .multi-presenter-extended .multi-presenter-image {
    position: relative;
    height: 300px;
    width: 300px;
    margin: 0 auto;
    padding: 30px 0 20px 0;
  }

  .multi-presenter-extended .multi-presenter-image:before {
    position: absolute;
    top: 30px;
    z-index: 20;
    width: 150%;
    height: 150%;
    margin: 0;
    padding: 0;
    background-image: url('../../img/multi-presenter-circle-overlay.svg');
    background-repeat: no-repeat;
    background-size: contain;
    content: " ";
  }

  .multi-presenter-extended .multi-presenter-image img {
    clip-path: circle();
    width: 100%;
    height: 100%;
    object-fit: cover
  }

  .multi-presenter-extended .multi-presenter-content {
    margin: 0;
    padding: 20px 20px 0 20px;
    text-align: center;
  }

  .multi-presenter-extended .multi-presenter-primary h1, #content .multi-presenter-extended .multi-presenter-primary h1,
  .multi-presenter-extended .multi-presenter-primary h2, #content .multi-presenter-extended .multi-presenter-primary h2,
  .multi-presenter-extended .multi-presenter-primary h3, #content .multi-presenter-extended .multi-presenter-primary h3,
  .multi-presenter-extended .multi-presenter-primary p, #content .multi-presenter-extended .multi-presenter-primary p,
  .multi-presenter-extended .multi-presenter-secondary h1, #content .multi-presenter-extended .multi-presenter-secondary h1,
  .multi-presenter-extended .multi-presenter-secondary h2, #content .multi-presenter-extended .multi-presenter-secondary h2,
  .multi-presenter-extended .multi-presenter-secondary h3, #content .multi-presenter-extended .multi-presenter-secondary h3,
  .multi-presenter-extended .multi-presenter-secondary p, #content .multi-presenter-extended .multi-presenter-secondary p{
    color: #fff;
  }

  /* layout: content / highlight box blue */

  .highlight-box-blue > div {
    padding: 15px;
  }

  .highlight-box-blue > div {
    background-size: 100px;
  }

  .highlight-box-blue > div:after {
    background-size: 100px;
  }

  /* layout: content / box footnote */

  .box-footnote > div {
    border-top: 5px solid $footnote-border;
  }

  /* layout: content / highlight box gradient */

  .highlight-box-gradient {

  }

  .highlight-box-gradient > div {
    padding: 50px;
  }

  .highlight-box-gradient > div:before {
    width: 100px;
    height: 100px;
    background-size: 100px;
  }

  .highlight-box-gradient > div:after {
    width: 100px;
    height: 100px;
    background-size: 100px;
  }

  /* layout: content / highlight box gradient dark */

  .highlight-box-gradient-dark {

  }

  .highlight-box-gradient-dark > div {
    padding: 50px;
  }

  .highlight-box-gradient-dark > div:before {
    width: 100px;
    height: 100px;
    background-size: 100px;
  }

  .highlight-box-gradient-dark > div:after {
    width: 100px;
    height: 100px;
    background-size: 100px;
  }

  /* layout: content / box headline white */

  .box-headline-white-left, #content .richText.box-headline-white-left,
  .box-headline-white-right, #content .richText.box-headline-white-right {
    margin: 0;
    padding: 10px 0;
  }

  .box-headline-white-left > div {
    -webkit-border-top-left-radius: 30px;
    -webkit-border-bottom-left-radius: 30px;
    -moz-border-radius-topleft: 30px;
    -moz-border-radius-bottomleft: 30px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  }

  .box-headline-white-right > div {
    -webkit-border-top-right-radius: 30px;
    -webkit-border-bottom-right-radius: 30px;
    -moz-border-radius-topright: 30px;
    -moz-border-radius-bottomright: 30px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }

}

@include respond-to(desktop) {

  /* display */

  .only-desktop {
    display: block !important;
  }

  /* spacing */

  .space-inside-top-desktop {
    padding-top: 30px;
  }

  .space-inside-left-desktop {
    padding-left: 30px;
  }

  .space-inside-right-desktop {
    padding-right: 30px;
  }

  .space-inside-bottom-desktop {
    padding-bottom: 30px;
  }

  .space-inside-top-negative-30-desktop,
  #content .richText.space-inside-top-negative-30-desktop {
    margin-top: -30px;
  }

  .space-inside-top-negative-60-desktop,
  #content .richText.space-inside-top-negative-60-desktop {
    margin-top: -60px;
  }

  /* borders */

  .border-top-desktop {
    border-top: 1px solid $border-default;
  }

  .border-left-desktop {
    border-left: 1px solid $border-default;
  }

  .border-right-desktop {
    border-right: 1px solid $border-default;
  }

  .border-bottom-desktop {
    border-bottom: 1px solid $border-default;
  }

  /* mobile menu */

  .mobile-menu {
    display: none;
  }

  .reference-header .header-sidebar {
    flex-grow: 1;
  }

  #header .searchBox {
    display: none;
  }

  /* layout: content / article compact */

  #content .box-compact {
    padding-left: 60px;
    padding-right: 60px;
  }

  /* divider */

  .divider {
    @include desktop-padding();
  }

  /* layout: content */

  .main .richText {
    @include align-center(medium);
    @include desktop-padding();
  }

  /* snippets: page image */

  .reference-page-image {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .reference-page-image .image {
    padding-left: 0;
    padding-right: 0;
  }

  /* layout: footer */

  .reference-footer > .inner > .component-content {
    flex: 0 0 auto;
    flex-wrap: nowrap;
  }

  /* layout: content / multi presenter */

  .reference-multi-presenter {
    margin: 30px 0 0 0;
  }

  .reference-multi-presenter > div {
    margin: 0;
  }

  .multi-presenter {
    position: relative;
    overflow: hidden;
  }

  .multi-presenter .multi-presenter-primary h1, #content .multi-presenter .multi-presenter-primary h1,
  .multi-presenter .multi-presenter-secondary h1, #content .multi-presenter .multi-presenter-secondary h1 {
    margin: 0;
    padding: 10px 0 0 0;
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 3.2rem;
    color: $headline-primary-text;
  }

  .multi-presenter .multi-presenter-primary h2, #content .multi-presenter .multi-presenter-primary h2,
  .multi-presenter .multi-presenter-secondary h2, #content .multi-presenter .multi-presenter-secondary h2 {
    margin: 0;
    padding: 10px 0 0 0;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 2.2rem;
    color: $headline-secondary-text;
  }

  .multi-presenter .multi-presenter-primary h3, #content .multi-presenter .multi-presenter-primary h3,
  .multi-presenter .multi-presenter-secondary h3, #content .multi-presenter .multi-presenter-secondary h3 {
    margin: 0;
    padding: 10px 0 0 0;
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.8rem;
    color: $headline-primary-text;
  }

  .multi-presenter .multi-presenter-primary p, #content .multi-presenter .multi-presenter-primary p,
  .multi-presenter .multi-presenter-secondary p, #content .multi-presenter .multi-presenter-secondary p {
    margin: 0;
    padding: 10px 0 0 0;
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 1.5rem;
    color: $headline-primary-text;
  }

  .multi-presenter .multi-presenter-cta {
    position: absolute;
    width: 520px;
    height: 150px;
    left: 80px;
    z-index: 100;
  }

  .multi-presenter .multi-presenter-cta h1, #content .multi-presenter .multi-presenter-cta h1,
  .multi-presenter .multi-presenter-cta h2, #content .multi-presenter .multi-presenter-cta h2,
  .multi-presenter .multi-presenter-cta h3, #content .multi-presenter .multi-presenter-cta h3,
  .multi-presenter .multi-presenter-cta p, #content .multi-presenter .multi-presenter-cta p {
    margin: 0;
    padding: 10px 0 0 0;
  }

  /* layout: content / multi presenter > simple */

  .multi-presenter-simple {
    height: 585px;
  }

  .multi-presenter-simple .multi-presenter-bg {
    height: 100%;
    -webkit-mask-image: url(../../img/multi-presenter-simple-mask.svg);
    -webkit-mask-position: top center;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: 100% 100%;
    mask-image: url(../../img/multi-presenter-simple-mask.svg);
    mask-position: top;
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
  }

  .multi-presenter-simple .multi-presenter-bg-image {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-position: center center;
    background-size: cover;
  }

  .multi-presenter-simple .multi-presenter-cta {
    top: 460px;
  }

  .multi-presenter-simple-gradient .multi-presenter-bg-image {
    left: 400px;
  }

  .multi-presenter-simple-gradient .multi-presenter-bg:before,
  .multi-presenter-simple-transparent .multi-presenter-bg:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-position: left center;
    background-size: cover;
    content: " ";
  }

  .multi-presenter-simple-gradient .multi-presenter-bg:before {
    background-image: url(../../img/multi-presenter-gradiant-overlay.png);
  }

  .multi-presenter-simple-transparent .multi-presenter-bg:before {
    background-image: url(../../img/multi-presenter-transparent-overlay.png);
  }

  .multi-presenter-simple .multi-presenter-content {
    position: absolute;
    box-sizing: border-box;
    padding: 30px 30px 30px 60px;
    top: 222px;
    transform: translateY(-50%);
    left: 0;
    width: 550px;
    height: auto;
    max-height: 400px;
    z-index: 100;
    overflow-y: auto;
  }

  .multi-presenter-simple-transparent .multi-presenter-content p {
    color: #000 !important;
  }

  .multi-presenter-simple-transparent .multi-presenter-content p a {
    color: $link-default;
    text-decoration: underline;
  }

  .multi-presenter-simple-gradient .multi-presenter-content {
    background: #fff;
    -webkit-border-top-right-radius: 40px;
    -webkit-border-bottom-right-radius: 40px;
    -moz-border-radius-topright: 40px;
    -moz-border-radius-bottomright: 40px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
  }

  /* layout: content / multi presenter > extended */

  .multi-presenter-extended {
    height: 650px;
  }

  .multi-presenter-extended .multi-presenter-bg {
    height: 100%;
    background-image: url('../../img/multi-presenter-bg.svg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -webkit-mask-image: url(../../img/multi-presenter-extended-mask.svg);
    -webkit-mask-position: top center;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: 100% 100%;
    mask-image: url(../../img/multi-presenter-extended-mask.svg);
    mask-position: top;
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
  }

  .multi-presenter-extended .multi-presenter-bg-image {
    display: none;
  }

  .multi-presenter-extended .multi-presenter-image {
    position: absolute;
    top: 60px;
    left: 180px;
    height: 300px;
    width: 300px;
    margin: 0;
    padding: 0;
  }

  .multi-presenter-extended .multi-presenter-image:before {
    position: absolute;
    top: -10px;
    left: -10px;
    z-index: 20;
    width: 160%;
    height: 160%;
    margin: 0;
    padding: 0;
    background-image: url('../../img/multi-presenter-circle-overlay.svg');
    background-repeat: no-repeat;
    background-size: contain;
    content: " ";
  }

  .multi-presenter-extended .multi-presenter-image img {
    clip-path: circle();
    width: 100%;
    height: 100%;
    object-fit: cover
  }

  .multi-presenter-extended .multi-presenter-primary {
    position: absolute;
    top: 70px;
    right: 60px;
    width: 500px;
    height: 315px;
    vertical-align: bottom;
  }

  .multi-presenter-extended .multi-presenter-primary h1, #content .multi-presenter-extended .multi-presenter-primary h1,
  .multi-presenter-extended .multi-presenter-primary h2, #content .multi-presenter-extended .multi-presenter-primary h2,
  .multi-presenter-extended .multi-presenter-primary h3, #content .multi-presenter-extended .multi-presenter-primary h3,
  .multi-presenter-extended .multi-presenter-primary p, #content .multi-presenter-extended .multi-presenter-primary p,
  .multi-presenter-extended .multi-presenter-secondary h1, #content .multi-presenter-extended .multi-presenter-secondary h1,
  .multi-presenter-extended .multi-presenter-secondary h2, #content .multi-presenter-extended .multi-presenter-secondary h2,
  .multi-presenter-extended .multi-presenter-secondary h3, #content .multi-presenter-extended .multi-presenter-secondary h3,
  .multi-presenter-extended .multi-presenter-secondary p, #content .multi-presenter-extended .multi-presenter-secondary p{
    color: #fff;
  }

  .multi-presenter-extended .multi-presenter-secondary {
    position: absolute;
    bottom: 50px;
    right: 60px;
    width: 340px;
    min-height: 30px;
    max-height: 140px;
  }

  .multi-presenter-extended .multi-presenter-cta {
    top: 460px;
  }

  /* layout: content / highlight box blue */

  .highlight-box-blue > div {
    padding: 100px;
  }

  .highlight-box-blue > div {
    background-size: 250px;
  }

  .highlight-box-blue > div:after {
    background-size: 250px;
  }

  /* layout: content / highlight box gradient */

  .highlight-box-gradient {

  }

  .highlight-box-gradient > div {
    padding: 50px;
  }

  .highlight-box-gradient > div:before {
    width: 250px;
    height: 250px;
    background-size: 250px;
  }

  .highlight-box-gradient > div:after {
    width: 250px;
    height: 250px;
    background-size: 250px;
  }

  /* layout: content / highlight box gradient dark */

  .highlight-box-gradient-dark {

  }

  .highlight-box-gradient-dark > div {
    padding: 50px;
  }

  .highlight-box-gradient-dark > div:before {
    width: 250px;
    height: 250px;
    background-size: 250px;
  }

  .highlight-box-gradient-dark > div:after {
    width: 250px;
    height: 250px;
    background-size: 250px;
  }

  /* layout: content / box headline white */

  .box-headline-white-left, #content .richText.box-headline-white-left {
    margin: 0;
    padding: 0;
  }

  .box-headline-white-right, #content .richText.box-headline-white-right {
    margin: 0;
    padding: 0;
  }

  .box-headline-white-left, #content .richText.box-headline-white-left {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 999;
    max-width: 90%;
  }

  .box-headline-white-right, #content .richText.box-headline-white-right {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 999;
    max-width: 90%;
  }

  /* layout: content / box footnote */

  .box-footnote > div {
    border-left: 8px solid $footnote-border;
  }

}

@include respond-to(large-desktop) {

  /* home */


}
