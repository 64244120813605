
/*

Theme colors

*/

$background-default: #ffffff;

$text-default: #5f5b4d;
$text-light: #a8a69e;
$text-dark: #ffffff;

$link-default: #007182;
$link-dark: #3ac3c9;

$navigation-hover: #3ac3c9;
$navigation-active: #5f5b4d;
$navigation-menu-flyout: #3ac3c9;

$headline-primary-text: #007182;
$headline-secondary-text: #3ac3c9;

$border-default: #f3f8fa;

$gray-light-bg: #f6f6f5;
$gray-light-border: #e0e0dd;

$green-light-bg: #f3f8f9;
$green-light-border: #e3eff1;

$green-dark-bg: #007182;

$hightlight-box-blue-bg: #f1f9fa;
$hightlight-box-border-green-bg: #9CE1E4;

$button-primary-bg: #007182;
$button-primary-text: #ffffff;
$button-secondary-bg: #daeaed;
$button-secondary-text: #007182;

$article-teaser-border: #daeaed;
$article-teaser-border-bg: #f1f9fa;

$box-green-light-bg: #f1f9fa;
$box-green-border: #6bd2d6;

$highlight-primary-text: #ffffff;
$highlight-primary-bg-start: #057485;
$highlight-primary-bg-end: #2eb8c0;

$highlight-secondary-text: #007182;
$highlight-secondary-bg-start: #c1e0e3;
$highlight-secondary-bg-end: #daeaed;

$footnote-border: #daeaed;
$footnote-bg: #f1f9fa;
$footnote-text: #5f5b4d;