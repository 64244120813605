@import '../core/mixins';

.accordion-container {
  padding: 15px 0;
  margin: 0;
  list-style: none;
}

.accordion-slide {
  border-top: 1px solid $green-light-border;
}

.accordion-slide.first {
  border-top: none;
}

.accordion-head {
  position: relative;
  display: block;
  padding: 15px 30px;
  color: $headline-primary-text;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  background: transparent;
}

.accordion-head:before {
  position: absolute;
  left: 0;
  top: 28px;
  @include font-awesome($fa-var-plus);
}

.is-active .accordion-head:before {
  @include font-awesome($fa-var-minus);
}

.accordion-title-link {
  position: relative;
  display: inline-block;
  color: $headline-primary-text;
  vertical-align: middle;
  min-height: 30px;
}

.image-accordion .accordion-title-image {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 34px;
  max-height: 34px;
}

.image-accordion .accordion-title-text {
  display: inline-block;
  padding: 5px 0 0 50px;
}

.accordion-content {
  margin-top: -15px;
  padding: 0 0 30px 28px;
}

.accordion-content .richText {
  margin: 0;
  padding: 0;
}

.accordion-content-wrapper {
  border-left: 0;
  border-right: 0;
  border-top: 0;
}

.mod-js .accordion-content-wrapper {
  position: relative;
  height: 0;
  top: -999999px;
  left: -999999px;
  overflow: hidden;
  box-sizing: border-box;
  visibility: hidden;
}

.mod-js .accordion-head {
  position: relative;
  cursor: pointer;
}

.mod-js .accordion-slide.is-active > .accordion-head:hover,
.mod-js .accordion-slide.is-active > .accordion-head {
  cursor: pointer;
}

.mod-js .accordion-slide.is-active > .accordion-head:hover:after,
.mod-js .accordion-slide.is-active > .accordion-head:after {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mod-js .accordion-slide .accordion-head:hover {
  color: $headline-primary-text;
}

.mod-js .accordion-slide.is-active > .accordion-head {
  color: $headline-primary-text;
}

.mod-js .accordion-slide.is-active > .accordion-head:hover {
  background-color: transparent;
}

.mod-js .accordion-slide.is-active > .accordion-content-wrapper {
  position: relative;
  top: auto;
  left: auto;
  height: auto;
  visibility: visible;
}

.mod-js .accordion-slide.is-active:last-child > .accordion-content-wrapper {
  border-bottom: none;
}
